import React from "react"
import { Link } from "gatsby"

const PageBanner = ({ pageTitle, homePageUrl, homePageText, bannerClassname = "page-title-area", activePageText, breadcrumbs, makeH1=true }) => {
  return (
    <div className={bannerClassname}>
      <div className="container">
        <div className="page-title-content">
          {makeH1 && <h1>{pageTitle}</h1>}
          {!makeH1 && <h2>{pageTitle}</h2>}
          <ul>
          {breadcrumbs &&
            breadcrumbs.map((textLinkArray) => (
              <li key={textLinkArray[0]}>
                <Link to={textLinkArray[1]}>
                  {textLinkArray[0]}
                </Link>
              </li>
            ))}
            {!breadcrumbs &&
            <li>
              <Link to={homePageUrl}>
                {homePageText}
              </Link>
            </li>
            }
            <li>{activePageText}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PageBanner
