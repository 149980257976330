import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import Seo from '../components/App/Seo'
import {graphql} from "gatsby";
import ImpressumContent from "../components/AboutUs/ImpressumContent"

const Impressum = ({data, location: {origin}}) => {
  return (
    <Layout>
      <Seo title={"Das Impressum der LOGENTIS GmbH"}
           description={"Das offizielle LOGENTIS Impressum inkl. Link zum " +
           "Datenschutzstatement und allen anderen rechtlichen Details."}
           image={"/images/og/logentis-main-og.png"}
      >
      </Seo>
      <Navbar />
      <PageBanner
        pageTitle="Impressum"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Impressum"
      />
      <ImpressumContent/>
      <Footer />
    </Layout>
  );
}


export default Impressum;

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        siteUrl
      }
    }
}
`;
